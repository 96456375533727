<template>
  <div :class="['content', align]">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="content" v-html="content" />
    <div v-if="countdownDate && !state.countDownExpired" class="countdown flex-inline">
      <div v-for="time in state.dateDisplay" :key="time.text" class="date-item flex-inline">
        <div class="flex-col">
          <span class="number" v-text="time.value" />
          <span class="text" v-text="time.text" />
        </div>
      </div>
    </div>
    <div
      v-if="buttons && buttons.length"
      :class="['button-container', { 'flex-inline': buttons.length > 1 }]"
      :style="{ width: state.buttonsWidth }"
    >
      <CustomButton
        v-for="(button, index) in buttons"
        :key="button.button_text + index"
        :to="getInternalLink(index)"
        :href="getExternalLink(index)"
        class="cta"
        :class="{ 'mgn-l-1': index > 0 }"
        :style-name="button?.type"
        :style-color="button?.style_color"
      >
        {{ button.button_text }}
      </CustomButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonCS } from '~/types/contentstack';
import { formatNumDigits } from '~/util/string';
import { getFutureTime } from '~/util/date';
import { extractInternalLink } from '~/util/contentstack/csHelpers';

const props = defineProps({
  countdownDate: {
    type: String,
    default: undefined,
  },
  buttons: {
    type: Array as () => ButtonCS[],
    required: false,
    default: null,
  },
  content: {
    type: String,
    default: undefined,
  },
  align: {
    type: String,
    default: '',
  },
});

const localePath = useLocalePath();

const state = reactive({
  countdownInterval: 0 as any,
  hideCountdownInterval: 0 as any,
  countDownExpired: false,
  hideCountdownExpired: false,
  dateDisplay: undefined as any,
  hasMounted: false,
  buttonsWidth: '20rem',
});

onMounted(() => {
  if (props?.countdownDate) {
    const countDownDate = new Date(props?.countdownDate).getTime();
    state.countdownInterval = setInterval(() => {
      countdownMovement(countDownDate);
    }, 1000);
    countdownMovement(countDownDate);
  }
  state.hasMounted = true;
});

onUnmounted(() => {
  clearInterval(state.countdownInterval);
  clearInterval(state.hideCountdownInterval);
});

// TODO - refactor here and topbanner as util functions
function countdownMovement(countDownDate: number) {
  const { days, hours, minutes, seconds, distance } = getFutureTime(countDownDate);

  // If the count down is finished, hide component
  if (distance < 0) {
    clearInterval(state.countdownInterval);
    state.countDownExpired = true;
  } else {
    state.dateDisplay = [
      {
        text: 'DAYS',
        value: formatNumDigits(days, 2),
      },
      {
        text: 'HRS',
        value: formatNumDigits(hours, 2),
      },
      {
        text: 'MIN',
        value: formatNumDigits(minutes, 2),
      },
      {
        text: 'SEC',
        value: formatNumDigits(seconds, 2),
      },
    ];
  }
}
function getInternalLink(i: number) {
  const url = extractInternalLink(props?.buttons?.[i]?.internal_linkConnection?.edges?.[0]) || undefined;
  const localizedUrl = url && localePath ? localePath(url) : url;
  return localizedUrl;
}
function getExternalLink(i: number) {
  return props?.buttons?.[i]?.external_link?.href;
}
</script>

<style lang="scss" scoped>
.countdown {
  width: fit-content;
  margin: 0 auto;
  padding: 6px 8px;
  gap: 6px;
  color: $color-primary-600;
  background-color: $color-neutral-white;
  border-radius: 4px;
}
.date-item {
  .flex-col {
    align-items: center;
  }
  .number {
    font-size: 14px;
  }
  .text {
    font-size: 9px;
  }
  &:not(:last-child) {
    &::after {
      display: block;
      content: ':';
      margin-right: 2px;
      margin-left: 8px;
    }
  }
}
</style>
